::-webkit-scrollbar {
  background-color:#fff;
  width:16px
}
::-webkit-scrollbar-track {
  background-color:#fff
}
::-webkit-scrollbar-track:hover {
  background-color:#f4f4f4
}
::-webkit-scrollbar-thumb {
  background-color:#babac0;
  border-radius:16px;
  border:5px solid #fff
}
::-webkit-scrollbar-thumb:hover {
  background-color:#a0a0a5;
  border:4px solid #f4f4f4
}
::-webkit-scrollbar-button {display:none}

::selection {
  background-color: #B4D5FE;
  color: inherit;
}

.loader {
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

@primary-color: #2B5E51;@border-radius-base: 4px;@height-base: 40px;@body-background: #fff;@layout-header-background: #fff;@layout-body-background: #fff;@component-background: #ffffff;@select-border-color: none;@select-background: #F7F9F8;@switch-color: #2B5E51;@checkbox-color: #2B5E51;