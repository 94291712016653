/* SFProDisplay Black Italic */
@font-face{
  font-family: 'SFProDisplay-BlackItalic';
  src: url('./SFProDisplay-BlackItalic.eot');
  src: url('./SFProDisplay-BlackItalic.eot?#iefix') format('embedded-opentype'),
  url('./SFProDisplay-BlackItalic.woff2') format('woff2'),
  url('./SFProDisplay-BlackItalic.woff') format('woff'),
  url('./SFProDisplay-BlackItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

/* SFProDisplay Bold */
@font-face{
  font-family: 'SFProDisplay-Bold';
  src: url('./SFProDisplay-Bold.eot');
  src: url('./SFProDisplay-Bold.eot?#iefix') format('embedded-opentype'),
  url('./SFProDisplay-Bold.woff2') format('woff2'),
  url('./SFProDisplay-Bold.woff') format('woff'),
  url('./SFProDisplay-Bold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

/* SFProDisplay Semibold */
@font-face{
  font-family: 'SFProDisplay-Semibold';
  src: url('./SFProDisplay-Semibold.eot');
  src: url('./SFProDisplay-Semibold.eot?#iefix') format('embedded-opentype'),
  url('./SFProDisplay-Semibold.woff2') format('woff2'),
  url('./SFProDisplay-Semibold.woff') format('woff'),
  url('./SFProDisplay-Semibold.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

/* SFProDisplay Semibold Italic */
@font-face{
  font-family: 'SFProDisplay-SemiboldItalic';
  src: url('./SFProDisplay-SemiboldItalic.eot');
  src: url('./SFProDisplay-SemiboldItalic.eot?#iefix') format('embedded-opentype'),
  url('./SFProDisplay-SemiboldItalic.woff2') format('woff2'),
  url('./SFProDisplay-SemiboldItalic.woff') format('woff'),
  url('./SFProDisplay-SemiboldItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

/* SFProDisplay Regular */
@font-face{
  font-family: 'SFProDisplay-Regular';
  src: url('./SFProDisplay-Regular.eot');
  src: url('./SFProDisplay-Regular.eot?#iefix') format('embedded-opentype'),
  url('./SFProDisplay-Regular.woff2') format('woff2'),
  url('./SFProDisplay-Regular.woff') format('woff'),
  url('./SFProDisplay-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

/* SFProDisplay Regular Italic */
@font-face{
  font-family: 'SFProDisplay-RegularItalic';
  src: url('./SFProDisplay-RegularItalic.eot');
  src: url('./SFProDisplay-RegularItalic.eot?#iefix') format('embedded-opentype'),
  url('./SFProDisplay-RegularItalic.woff2') format('woff2'),
  url('./SFProDisplay-RegularItalic.woff') format('woff'),
  url('./SFProDisplay-RegularItalic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

/* SFProDisplay Medium */
@font-face{
  font-family: 'SFProDisplay-Medium';
  src: url('./SFProDisplay-Medium.eot');
  src: url('./SFProDisplay-Medium.eot?#iefix') format('embedded-opentype'),
  url('./SFProDisplay-Medium.woff2') format('woff2'),
  url('./SFProDisplay-Medium.woff') format('woff'),
  url('./SFProDisplay-Medium.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

/* SFProDisplay Medium Italic */
@font-face{
  font-family: 'SFProDisplay-MediumItalic';
  src: url('./SFProDisplay-MediumItalic.eot');
  src: url('./SFProDisplay-MediumItalic.eot?#iefix') format('embedded-opentype'),
  url('./SFProDisplay-MediumItalic.woff2') format('woff2'),
  url('./SFProDisplay-MediumItalic.woff') format('woff'),
  url('./SFProDisplay-MediumItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

/* SFProDisplay Light */
@font-face{
  font-family: 'SFProDisplay-Light';
  src: url('./SFProDisplay-Light.eot');
  src: url('./SFProDisplay-Light.eot?#iefix') format('embedded-opentype'),
  url('./SFProDisplay-Light.woff2') format('woff2'),
  url('./SFProDisplay-Light.woff') format('woff'),
  url('./SFProDisplay-Light.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

/* SFProDisplay Light Italic */
@font-face{
  font-family: 'SFProDisplay-LightItalic';
  src: url('./SFProDisplay-LightItalic.eot');
  src: url('./SFProDisplay-LightItalic.eot?#iefix') format('embedded-opentype'),
  url('./SFProDisplay-LightItalic.woff2') format('woff2'),
  url('./SFProDisplay-LightItalic.woff') format('woff'),
  url('./SFProDisplay-LightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

/* SFProDisplay Ultralight */
@font-face{
  font-family: 'SFProDisplay-Ultralight';
  src: url('./SFProDisplay-Ultralight.eot');
  src: url('./SFProDisplay-Ultralight.eot?#iefix') format('embedded-opentype'),
  url('./SFProDisplay-Ultralight.woff2') format('woff2'),
  url('./SFProDisplay-Ultralight.woff') format('woff'),
  url('./SFProDisplay-Ultralight.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

/* SFProDisplay Ultralight Italic */
@font-face{
  font-family: 'SFProDisplay-UltralightItalic';
  src: url('./SFProDisplay-UltralightItalic.eot');
  src: url('./SFProDisplay-UltralightItalic.eot?#iefix') format('embedded-opentype'),
  url('./SFProDisplay-UltralightItalic.woff2') format('woff2'),
  url('./SFProDisplay-UltralightItalic.woff') format('woff'),
  url('./SFProDisplay-UltralightItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

/* Circe Bold */
@font-face{
  font-family: 'Circe-Bold';
  src: url('Circe-Bold.eot');
  src: url('Circe-Bold.eot?#iefix') format('embedded-opentype'),
  url('Circe-Bold.woff2') format('woff2'),
  url('Circe-Bold.woff') format('woff'),
  url('Circe-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

/* Circe Regular */
@font-face{
  font-family: 'Circe-Regular';
  src: url('Circe-Regular.eot');
  src: url('Circe-Regular.eot?#iefix') format('embedded-opentype'),
  url('Circe-Regular.woff2') format('woff2'),
  url('Circe-Regular.woff') format('woff'),
  url('Circe-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

/* Circe Light */
@font-face{
  font-family: 'Circe-Light';
  src: url('Circe-Light.eot');
  src: url('Circe-Light.eot?#iefix') format('embedded-opentype'),
  url('Circe-Light.woff2') format('woff2'),
  url('Circe-Light.woff') format('woff'),
  url('Circe-Light.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}
